<template>
  <div>
    <div class="my-6 text-start">
      <h3 class="font-bold">Editar Setor</h3>
      <span class="text-sm text-gray-500">Verifique as informações abaixo e clique no botão <b>Salvar</b> para
        confirmar as modificações feitas
      </span>
    </div>

    <div class="card shadow-sm bg-white border p-8">
      <div class="flex lg:flex-row flex-col items-center">
        <div class="w-full p-2">
          <Input v-model="setor.nome" type="default" label="Nome do Setor " placeholder="Nome do Setor" />
        </div>
        <div class="w-full p-2">
          <Input v-model="setor.ordenar" type="number" label="Ordem do Setor" placeholder="Ordem do Setor" />
        </div>
        <div class="w-full p-2">
        <Input type="select" id="tipo" v-model="tipoSelect" label="Tipo" placeholder="Selecione um Tipo"
                :data="tipoOption" @keyupSearch="geTipo" />
        </div>
      </div>
      <div class="flex justify-end mt-5">
      <Button @click="edit" color="primary" text="Salvar"></Button>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET, PUT } from "../../services/api";
import { isEmpty } from "../../services/validate";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "EditarSetores",
  components: {
    Input,
    Button,
  },
  data() {
    return {
    }
  },

  setup() {
    
    const loader = inject("loading");
    const alert = inject("alert");
    const router = useRouter();
    const route = useRoute();
    const modal = inject("modal")
    const setor = ref({
      "nome": "",
      "ordenar": "",
      "tipo": ""
    });

    const tipoOption = ref([
      {
        label: 'Público',
        value: '1'
      },
      {
        label: 'Produtos',
        value: '2'
      },
      {
        label: 'Fichas',
        value: '3'
      }])

    const tipoSelect = ref('1')

    onMounted(async () => {
      loader.open();
      const response = await GET(`setores/${route.params.id}`);
      setor.value = response
      tipoSelect.value = setor.value.tipo
      loader.close();
    });

    const edit = async () => {
      setor.value.tipo = tipoSelect.value
      const validateNome = isEmpty(setor.value.nome);
      const validateOrdenar = isEmpty(setor.value.ordenar);
      const validateTipo = isEmpty( setor.value.tipo);

      if (validateNome || validateOrdenar || validateTipo) {
        alert.open("Atenção!", validateNome, "warning");
        alert.open("Atenção!", validateOrdenar, "warning"); 
        alert.open("Atenção!", validateTipo, "warning"); 
      } else {

        modal.open("Atenção", "Deseja realmente editar esse setor?", "warning", "Sim", async () => {
          try {
            delete setor.value.id
            delete setor.value.deleted_at
            await PUT(`setores/${route.params.id}`, setor.value)
            loader.open();
            alert.open(
              "Sucesso!",
              `Setor editado com sucesso!`,
              "success"
            );
            loader.close();
            router.push("/setores");
          } catch (e) {
            loader.close();
            alert.open("Atenção!", e.message, "warning");
          }
        },
          modal.close())
      }

    };

    

    return {
      setor,
      edit,
      tipoSelect,
      tipoOption
    };
  },
};
</script>

<style>
.btn {
  width: 100%;
  align-items: center;
  justify-content: center;
}
</style>
